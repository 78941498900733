import React, { useState } from 'react'

function Faq() {

  const [q1, setQ1] = useState(false)
  const [q2, setQ2] = useState(false)
  const [q3, setQ3] = useState(false)
  const [q4, setQ4] = useState(false)
  const [q5, setQ5] = useState(false)
  const [q6, setQ6] = useState(false)
  const [q7, setQ7] = useState(false)
  const [q8, setQ8] = useState(false)
  const [q9, setQ9] = useState(false)
  const [q10, setQ10] = useState(false)

  function toggleQ1(){   
    if(q1 === true){
      setQ1(false)
    } else{
      setQ1(true)
    }
  }
  function toggleQ2(){   
    if(q2 === true){
      setQ2(false)
    } else{
      setQ2(true)
    }
  }
  function toggleQ3(){   
    if(q3 === true){
      setQ3(false)
    } else{
      setQ3(true)
    }
  }
  function toggleQ4(){   
    if(q4 === true){
      setQ4(false)
    } else{
      setQ4(true)
    }
  }
  function toggleQ5(){   
    if(q5 === true){
      setQ5(false)
    } else{
      setQ5(true)
    }
  }
  function toggleQ6(){   
    if(q6 === true){
      setQ6(false)
    } else{
      setQ6(true)
    }
  }
  function toggleQ7(){   
    if(q7 === true){
      setQ7(false)
    } else{
      setQ7(true)
    }
  }
  function toggleQ8(){   
    if(q8 === true){
      setQ8(false)
    } else{
      setQ8(true)
    }
  }
  function toggleQ9(){   
    if(q9 === true){
      setQ9(false)
    } else{
      setQ9(true)
    }
  }
  function toggleQ10(){   
    if(q10 === true){
      setQ10(false)
    } else{
      setQ10(true)
    }
  }

  return (

        <div className="content wide center faq"> 
          <h2 className="heading">FAQs</h2>
          <br/>
          <div className="question-container">
            <button className="question" onClick={toggleQ1}>
              <h3>How would my brand benefit from an AR Experience?</h3>
              <span className={`arrow-down ${!q1 ? "" : "expanded"}`}/>
            </button>
            { q1 ?
              <div className="answer">
                <p>Augmented Reality (AR) experiences can elevate your brand by creating engaging, interactive, and memorable interactions with your audience. Benefits include increased customer engagement, enhanced brand visibility, improved product understanding, and potential sales growth.</p> 
              </div>
            :null }
          </div>

          <hr/>

          <div className="question-container">
            <div>
              <button className="question" onClick={toggleQ2}>
                <h3>How fast will I receive my concept?</h3>
                <span className={`arrow-down ${!q2 ? "" : "expanded"}`}/>
              </button>
              { q2 ?
              <div className="answer">
                <p>On average, the initial concepts are completed in just a week. However, the delivery time for your AR final experience can vary depending on the complexity of the project. We strive to provide a timeline estimate once we understand your specific needs and requirements.</p>
              </div>
              :null }
            </div>
          </div>

          <hr/>

          <div className="question-container">
            <div>
              <button className="question" onClick={toggleQ3}>
                <h3>Who are the designers and developers?</h3>
                <span className={`arrow-down ${!q3 ? "" : "expanded"}`}/>
              </button>
              { q3 ?
              <div className="answer">
                <p>You might be surprised to hear this, but this service is actually being run by only one person. This means you'll work directly with me, founder of ENCHANT VISION. However, in some edge cases I may use external help.</p>
              </div>
              :null }
            </div>
          </div>

          <hr/>

          <div className="question-container">
            <div>
              <button className="question" onClick={toggleQ4}>
                <h3>How does it work?</h3>
                <span className={`arrow-down ${!q4 ? "" : "expanded"}`}/>
              </button>
              { q4 ?
              <div className="answer">
                <p>We are all about speed and efficiency - without sacrificing quality. For that we've cut-out the time spent with meetings by replacing it with a detailed onboarding form and written feedback.</p>
                <p>1. You fill in the onboarding form with as many details as you can.</p>
                <p>2. After payment confirmation, you will receive further instructions and access to a Google Drive folder where if needed, you can upload aditional materials according to the content of the form.</p>
                <p>3. You receive the start confirmation via email and on average in just a week we will send you the initial concept presentation for you to review.</p>
                <p>4. You give us feedback using the commenting system in Google Docs/Presentation and we do our best to accomodate them.</p>
                <p>5. After we are done, we generate a special QR code to access your experience.</p>
              </div>
              :null }
            </div>
          </div>

          <hr/>
          
          <div className="question-container">
            <div>
              <button className="question" onClick={toggleQ5}>
                <h3>What are the limitation of this technology?</h3>
                <span className={`arrow-down ${!q5 ? "" : "expanded"}`}/>
              </button>
              { q5 ?
              <div className="answer">
                <p>While AR technology offers immense potential, it is still a field in it's infancy that may have limitations such as device compatibility, certain design constrains and the need for a stable internet connection. We will help you navigate these limitations and find the best solutions for your project.</p>
              </div>
              :null }
            </div>
          </div>

          <hr/>

          <div className="question-container">
            <div>
              <button className="question" onClick={toggleQ6}>
                <h3>What if I don't like the design?</h3>
                <span className={`arrow-down ${!q6 ? "" : "expanded"}`}/>
              </button>
              { q6 ?
              <div className="answer">
                <p>We value your satisfaction. If you're not satisfied with the initial concept, we'll work closely with you to make revisions until we achieve the desired result. Your feedback is crucial in ensuring the final product aligns with your vision. Please note that once the concept is approved, any modifications will come at an extra cost as everything needs to be remade from scratch.</p>
              </div>
              :null }
            </div>
          </div>

          <hr/>

          <div className="question-container">
            <div>
              <button className="question" onClick={toggleQ7}>
                <h3>What if I have a different need?</h3>
                <span className={`arrow-down ${!q7 ? "" : "expanded"}`}/>
              </button>
              { q7 ?
              <div className="answer">
                <p>We are flexible and adaptable. If you have unique or specific requirements beyond our standard offerings, please let us know. We'll do our best to accommodate your needs and customize our services accordingly.</p>
              </div>
              :null }
            </div>
          </div>

          <hr/>

          <div className="question-container">
            <div>
              <button className="question" onClick={toggleQ8}>
                <h3>Are there any refunds if I don't like the service?</h3>
                <span className={`arrow-down ${!q8 ? "" : "expanded"}`}/>
              </button>
              { q8 ?
              <div className="answer">
                {/*<p>We are committed to delivering high-quality AR experiences that meet your expectations. If you are not satisfied with our service, please contact us to discuss your concerns, and we will work with you to find a suitable resolution. Refunds may be considered on a case-by-case basis. Your satisfaction is our priority.</p>*/}
                <p>Due to the high quality nature of the work, there will be no refunds issued, but we are confident that you'll love our work and we will do our best to satisfy your needs.</p>
              </div>
              :null }
            </div>
          </div>
          <br/>
          <br/>
        </div>
    
  );
}

export default Faq;
