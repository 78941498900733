import React, { useState, useEffect, useRef } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation } from '../Animations'

//ASSETS
import marker from '../../assets/img/marker.svg'
import face from '../../assets/img/face.svg'
import placed from '../../assets/img/placed.svg'

const Step2 = ({ brandEmail }) => {


  //Checkbox
  const [selected, setSelected] = useState(false);

  const handleSelection = event => {
    if (event.target.checked) {
      console.log('✅ Checkbox is checked');
    } else {
      console.log('⛔️ Checkbox is NOT checked');
    }
    setSelected(current => !current);
  };


  return (
    <>
      <motion.div 
                  key="step2"
                  className="step step2 contentAnimation"
                  variants={ contentAnimation } 
                  animate='show' 
                  exit='hide'
                >
                  <div className="grid">
                    <div className="grid-element">
                      <h2>Technology</h2>
                      <p>Select the desired technologies.</p>
                    </div>
                    <div className="grid-element"/>
                  </div>

                  <div className="grid">
                    <label for="marker" className={'grid-element checkbox ' + (selected ? "selected" : '') }>
                      <input type="checkbox" id="marker" name="marker" value="Marker AR" onChange={handleSelection}/>
                      <img src={marker} />
                      <br/>
                      <h3>Marker AR</h3>
                      <p>Experience triggered by a symbol or picture.</p>
                    </label>

                    <label for="face" className={'grid-element checkbox ' + (selected ? "selected" : '') }>
                      <input type="checkbox" id="face" name="face" value="Face filter AR" onChange={handleSelection}/>
                      <img src={face} />
                      <br/>
                      <h3>Face filter AR</h3>
                      <p>Experience triggered by a face, as a mask.</p>
                    </label>

                    <label for="placed" className={'grid-element checkbox ' + (selected ? "selected" : '') }>
                      <input type="checkbox" id="placed" name="placed" value="Placed in space AR" onChange={handleSelection}/>
                      <img src={placed} />
                      <br/>
                      <h3>In space AR</h3>
                      <p>Experience that can be placed on a surface.</p>
                    </label>
                  </div>
                  
                  <div className={'checkbox small' + (selected ? "selected" : '') }>
                    <label for="notsure">
                      <p>
                        <input type="checkbox" id="notsure" name="notsure" value="Not Sure" onChange={handleSelection}/>
                        I am not sure
                      </p>
                    </label>
                  </div>
  
              
                </motion.div>
    </>
  );
}

export default Step2;
