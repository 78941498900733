export const logoAnimation = {
	show: { 
          // y: [100, 100], 
          opacity: [0, 1,],
          scale: [0, 1],
         
          transition: {
            delay: 0,
            duration: 1,
            
          }  
        },
	hide: { 
          // y: [100, 100], 
          opacity: [1, 0], 
          scale: [1, 0], 
           
          transition: {
            delay: 0,
            duration: 0.5,
            
          }
        }
};

export const navbarAnimation = {
  show: { 
          // y: [-100, 0], 
          opacity: [0, 1,],
          // scale: [0, 1],
         
          transition: {
            delay: 3.5,
            duration: 3,
            
          }  
        },
  hide: { 
          // y: [0, -100], 
          opacity: [1, 0], 
          // scale: [1, 0], 
           
          transition: {
            delay: 0,
            duration: 0.5,
            
          }
        }
};

export const contentAnimation = {
  show: { 
          opacity: [0, 1], 
          
          transition: {
            delay: 0,
            duration: 1
            
          }  
        }
        ,
  hide: { 
          opacity: [1, 0], 
          
          transition: {
            delay: 0.15,
            duration: 0.5
            
          }
        }
};

export const landingAnimation = {
  show: { 
          opacity: [0, 1], 
          
          transition: {
            delay: 3,
            duration: 1
            
          }  
        }
        ,
  hide: { 
          opacity: [1, 0], 
          
          transition: {
            delay: 0.15,
            duration: 0.5
            
          }
        }
};


export const splashScreenAnimation = {
  show: { 
          opacity: [0, 1, 1, 1, 0], 
          transition: {
            delay: 0,
            duration: 3
            
          }  
        }
        ,
  hide: { 
          opacity: [0, 0], 
          
          transition: {
            delay: 0,
            duration: 0
            
          }
        }
};

export const splashLogoAnimation = {
  show: { 
          opacity: [0, 1, 1, 1, 0], 
      
          transition: {
            delay: 0.15,
            duration: 1
            
          }  
        }
        ,
  hide: { 
          opacity: [0, 0], 
          
          transition: {
            delay: 0,
            duration: 0
            
          }
        }
};



export const formAnimation = {
  show: { 
          opacity: [0, 1], 
          y: [550, 0],
          
          transition: {
            delay: 0.5,
            duration: 1
          }  
        }
        ,
  hide: { 
          opacity: [1, 0], 
          
          transition: {
            delay: 0,
            duration: 0
            
          }
        }
};

export const indicatorAnimation = {
  show: { 
          opacity: [0, 1], 
          x: [550, 0],
          
          transition: {
            delay: 1.5,
            duration: 1
          }  
        }
        ,
  hide: { 
          opacity: [1, 0], 
          
          transition: {
            delay: 0,
            duration: 0
            
          }
        }
};


export const fadeAnimation = {
  show: {  
          scale: [0.9, 1], 
          opacity: [0, 1], 
           
          transition: {
            duration: 1,
            delay: 0
          } 
        },
  hide: {  
          scale: [1, 0.9], 
          opacity: [1, 0], 
          
          transition: {
            duration: 1,
            delay: 0
          }
        }
};

export const slowFadeAnimation = {
  show: {  
          // scale: [0.65, 1], 

          opacity: [0, 1], 
           
          transition: {
            duration: 0.7,
            delay: 0
          } 
        },
  hide: {  
          // scale: [1, 0.9], 
          opacity: [1, 0], 
          
          transition: {
            duration: 1,
            delay: 0
          }
        }
};


export const modalAnimation = {
  show: { 
          opacity: [0, 1], 
          // scale: [0.8, 1],
          y: [400, 0],

          transition: {
            duration: 0.35, 
            delay: 0.15,
            ease: "easeIn"
          }
        },
  hide: {  
          opacity: [1, 0], 
          // scale: [1, 0.98],
          y: [0, 400], 

          transition: {
            duration: 0.5,
            delay: 0,
            ease: "easeOut"
          }
        }
};

export const quickFadeAnimation = {
  show: { 
          opacity: [0, 1], 
          transition: {
            delay: 0
          } 
        },
  hide: { 
          opacity: [1, 0], 
          transition: {
            delay: 0
          }
        }
};

export const chatAnimation = {
  show: { 
          y: [700, 0], 
          opacity: [0, 1],
          transition: {
            delay: 1.5,
            ease: 'linear'
          }
        },
  hide: { 
          y: [0, 700], 
          opacity: [1, 0],
          transition: {
            delay: 0,
            ease: 'linear'
          }
        }
};

export const chatBarAnimation = {
  show: { 
          y: [0, 0], 
          opacity: [0, 1],
          transition: {
            delay: 0,
            ease: 'easeIn'
          }
        },
  hide: { 
          y: [0, 100], 
          opacity: [1, 1],
          transition: {
            duration: 1,
            delay: 1,
            ease: 'easeOut'
          }
        }
};

// export const navbarAnimation = {
//   show: { 
//           opacity: [0, 1],
//           transition: {
//             delay: 1
//           }
//         },
//   hide: { 
//           opacity: [1, 0],
//           transition: {
//             delay: 0
//           }
//         }
// };

export const circleAnimation = {
  show: { 
          // opacity: [0, 1],
          scale: [0, 1],
          transition: {
            delay: 0.75
          }
        },
  hide: { 
          // opacity: [1, 0],
          scale: [1, 0],
          transition: {
            delay: 0.25
          }
        }
};

