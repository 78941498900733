import { Link } from 'react-router-dom'

const Notification = ({ notificationMessage }) => {

  return (
    <div className="notification">
      <h4 className="">{notificationMessage}</h4>
    </div>
  );
}

export default Notification;
