import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import { AnimatePresence, motion, useViewportScroll, useTransform } from 'framer-motion'
import { splashScreenAnimation, landingAnimation, contentAnimation } from '../components/Animations'

import { useInView } from "react-intersection-observer";

import { SEO } from '../components/SEO';
import Analytics from '../components/Analytics';

//Components
import Navbar from '../components/Navbar';
import Notification from '../components/Notification';
import Header from '../components/Header';
import Footer from '../components/Footer';

import Pricing from '../components/Pricing';
import Faq from '../components/Faq';

//Assets
import logo from '../assets/img/logo.svg';
import demo from '../assets/img/demo.png';

import concept from '../assets/img/concept.svg';
import hosting from '../assets/img/hosting.svg';
import onair from '../assets/img/onair.svg';

import demoApp from '../assets/video/demo.mp4'


function Landing() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const scrollRef = useRef(null)

  function goToPacksFunction(e){
    let plan = document.getElementById("pricing");
    e.preventDefault();  // Stop Page Reloading
    plan && plan.scrollIntoView({ behavior: "smooth", block: "start"});
  }

  return (
    <>
      <Analytics pageName="Landing"/>
      <SEO
        title="ENCHANT VISION"
        description="We design AR experiences to enchant your audience."
        type="webapp"
        name="ENCHANT VISION"
      />

      <motion.div 
        key="splash"
        className="splash-screen splashScreenAnimation"
        variants={ splashScreenAnimation } 
        animate='show' 
        exit='hide'
      >
        <div className="splash-content">
          <img src={logo} className="splash-logo"/>
          <h4 className="section-title center gradient-text slow">ENCHANT VISION</h4>
        </div>
      </motion.div>
      
      
      <motion.div
        id="up"
        key="landing"
        className="landing landingAnimation"
        variants={ landingAnimation } 
        animate='show' 
        exit='hide'
      >
        <Navbar/>
        <Notification notificationMessage="Our service is not available at this moment. Try again later."/>


        <Header/>

        <section className="intro">
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ root: scrollRef }}
            transition={{ delay: 0, duration: 0.5 }} // Add a delay of 0.5 seconds
          >
          <div className="content">
            <br/><br/><br/><br/><br/><br/>
            <h4 className="section-title">Statement</h4>
            <p className="">We empower brands to thrive in the emerging metaverse era by leveraging Augmented Reality Experiences designed to increase success across the marketing funnel - from increasing brand engagement, to boosting sales.</p> 
            <br/><br/><br/><br/><br/><br/>
          </div>
          </motion.div>
        </section>

        <section> 
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ root: scrollRef }}
            transition={{ delay: 0.3, duration: 1 }} // Add a delay of 0.5 seconds
          >
          <div className="grid">
            <div className="grid-element">
              <img src={concept} className="logo left"/>
              <h3>We design your experience</h3>
              <p>Based on your brand needs and configuration, we create an artistic concept and design an experience to engage your audience.</p>
            </div>
            <div className="grid-element">
              <img src={hosting} className="logo left"/>
              <h3>We host your experience</h3>
              <p>You don’t need to worry about any technicalities. We take care of everything for you and generate a QR code linking to your experience.</p>
            </div>
            <div className="grid-element">
              <img src={onair} className="logo left"/>
              <h3>It works on air!</h3>
              <p>No app downloading is needed. Your audience is just a QR code scan away from immersing themselves in your experience.</p>
            </div>
          </div>
          </motion.div>
        </section>

        <section className="gradient">
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ root: scrollRef }}
            transition={{ delay: 0.3, duration: 1 }} // Add a delay of 0.5 seconds
          >
          <br/><br/><br/><br/><br/><br/>
          <div className="content">
            <h4 className="section-title">Use Cases</h4>
          </div>
          <h2 className="heading"><span className="gradient-text slow">#Art</span> <span className="gradient-text slow">#Entertainment</span> <span className="gradient-text slow">#Education</span> <span className="gradient-text slow">#Marketing</span> <span className="gradient-text slow">#Product</span> <span className="gradient-text slow">#Other</span></h2>
          <div className="content">
            <h4>We are proudly powering</h4>
            <h3><a href="https://cadou.digital" target="_blank">Cadou Digital</a> * <a href="https://martisor.digital" target="_blank">Martisor Digital</a> * <a href="https://selfie.gift" target="_blank">Selfie Gift</a></h3>
          </div>
          <br/><br/><br/><br/><br/><br/>
          </motion.div>
        </section>

        <section>
          
          <h4 className="section-title">Our Technology</h4>
          <div className="grid">
            <div className="grid-element middle">
              <motion.div 
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ root: scrollRef }}
                transition={{ delay: 0.75, duration: 1 }} // Add a delay of 0.5 seconds
              >
                <div className="question">
                  <h3>Marker AR</h3>
                  <p>A marker can be a photograph or a symbol that once scanned, will trigger on top of it the experience.</p>
                </div>
                <br/>
                <br/>
                <div className="question">
                  <h3>Filter AR</h3>
                  <p>Face filters are using Computer Vision technology to recognize human faces and overlap a “mask” on top.</p>
                </div>
              </motion.div>
            </div>

            <div className="grid-element">
              <motion.div 
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ root: scrollRef }}
                transition={{ delay: 0.3, duration: 1 }} // Add a delay of 0.5 seconds
              >
              <br/>
              <div className="app-screen">
                <img src={demo} className="bezel"/>
                <video className="demo-video" src={demoApp} autoPlay="autoplay" playsInline="playsinline" loop="true" muted="true"/>
              </div>
              <br/>
              </motion.div>
            </div>

            <div className="grid-element middle">
              <motion.div 
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ root: scrollRef }}
                transition={{ delay: 0.75, duration: 1 }} // Add a delay of 0.5 seconds
              >
                <div className="question">
                  <h3>Spatial AR</h3>
                  <p>This technology is recognizing surfaces, allowing users to place the experience or digital object on that surface.</p>
                </div>
                <br/>
                <br/>
                <div className="question">
                <h3>Geolocated AR <sup>soon</sup></h3>
                <p>We use GPS coordinates to make the AR experience available only at that particular location.</p>
                </div>
              </motion.div>
            </div>
            
          </div>
          <br/>
          <br/>
          <button className="button secondary center">Open the DEMO app</button>
          
        </section>

        <section id="pricing" className="pricing">
          <Pricing brandEmail="hi@enchant.vision"/>
        </section>

        <section id="faq" className="faq">
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ root: scrollRef }}
            transition={{ delay: 0.3, duration: 1 }} // Add a delay of 0.5 seconds
          >
            <Faq />
          </motion.div>
        </section>

        <section className="gradient">
          <div className="content wide">
            <br/> 
            <h2 className=" center"><span className="gradient-text slow">It’s time to enchant your audience.</span></h2>
            <br/>
            <button className="button center" onClick={goToPacksFunction}>Start now!</button>
            <br/>
            <br/>
            <br/>
          </div>
        </section>  

        <Footer brandName="ENCHANT VISION" brandEmail="hi@enchant.vision"/>

      </motion.div>
    </>
  );
}

export default Landing;
