import React, { useState } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'

import ReactGA from "react-ga4";

import Landing from './pages/Landing'
import Examples from './pages/Examples'
import Form from './pages/Form'
import Checkout from './pages/Checkout'
import Success from './pages/Success'
import PageNotFound from './pages/404'
import Legal from './pages/Legal'

import ViewerApp from './pages/ViewerApp'

function App() {
  ReactGA.initialize("G-2KMKK4Z31H");

  const location = useLocation();
  return (
    <div className="App">
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}> 
          <Route exact path="/" element={<Landing/>}/> 
          <Route exact path="/examples" element={<Examples/>}/> 
          <Route exact path="/start" element={<Form/>}/> 
          <Route exact path="/checkout" element={<Checkout/>}/> 
          <Route exact path="/success" element={<Success/>}/> 

          <Route exact path="/demo" element={<ViewerApp/>}/> 

          {/*<Route exact path="/help" element={<Help/>} />*/}
          {/*<Route exact path="/contact" element={<Contact/>} />*/}
          <Route exact path="/legal" element={<Legal/>} />

          <Route path="*" element={<PageNotFound/>}/>
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
