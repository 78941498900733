import React, { useState, useEffect, useRef } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation } from '../Animations'

//ASSETS
// import QRPreview from '../../assets/img/QR-preview.png'
import screen from '../../assets/img/app-logo-preview.png'
import demoLogo from '../../assets/img/logo-placeholder.svg';

const Step3 = ({ brandEmail }) => {

  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(demoLogo);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedFile(file);
        setFilePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <motion.div 
        key="step3"
        className="step step3 contentAnimation"
        variants={ contentAnimation } 
        animate='show' 
        exit='hide'
      >      
        <div className="grid">
          <div className="grid-element">  
            <h2>Branding</h2> 
            <p>Let's customize the viewer app with your visual identity.</p>
            <br/>        
            <h3>Upload your logo</h3>
            <div className="field">
              <h4 className="option">Please use a square transparent .png format. <br/>
                <input
                  type="file"
                  accept="image/*" // Optionally, specify file types you want to accept
                  onChange={handleFileChange}
                />
              </h4>
            </div>
          </div>

          <div className="grid-element">
            <div className="qr-preview">
              <img className="logo-preview" src={filePreview} alt="Branding Preview"/> 
            </div>

            <div className="app-screen form">
              <img className="logo-preview" src={filePreview} alt="Branding Preview"/> 
              {/*{filePreview && ("TEST")}*/}
              <img src={screen} className="bezel"/>
            </div>             
          </div>

        </div>
      </motion.div>
    </>
  );
}

export default Step3;
