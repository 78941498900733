import React, { useState, useEffect } from 'react';
import Slide from './Slide';

const Slider = ({ slides, status }) => {

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const [isProgressPaused, setIsProgressPaused] = useState(false);

  // useEffect(() => {
  //   let interval;
  //   if (currentSlideIndex < slides.length - 1) {
  //     interval = setInterval(() => {
  //       setProgress(prevProgress => {
  //         if (prevProgress >= 100) {
  //           clearInterval(interval);
  //           nextSlide();
  //           return 0;
  //         }
  //         return prevProgress + 1;
  //       });
  //     }, 50);
  //   }

  //   return () => clearInterval(interval);
  // }, [currentSlideIndex, slides.length]);

  useEffect(() => {
  let interval;
  if (currentSlideIndex < slides.length - 1 && !isProgressPaused) {
    interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          nextSlide();
          return 0;
        }
        return prevProgress + 1;
      });
    }, 50); // Increment every 50 milliseconds for a 1% increment per second.
  }

  return () => clearInterval(interval);
}, [currentSlideIndex, isProgressPaused, slides.length]);



  const nextSlide = () => {
    if (currentSlideIndex < slides.length - 1) {
      setProgress(0); 
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlideIndex > 0) {
      setProgress(0); 
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const isLastSlide = currentSlideIndex === slides.length - 1;

  return (
    
    <div className="slider">
      {!isLastSlide && (
        <div className="progress-bar">
          <div 
            className="progress" 
            style={{ width: `${progress}%` }}
            onMouseDown={() => setIsProgressPaused(true)}
            onMouseUp={() => setIsProgressPaused(false)}
          />
        </div>
      )}

      <button className="slide-back-btn" onClick={prevSlide} disabled={currentSlideIndex === 0} />

      <Slide image={slides[currentSlideIndex].image} title={slides[currentSlideIndex].title} description={slides[currentSlideIndex].description} />   
      
      {isLastSlide ? (
        <button className="button primary slide-cta-btn" onClick={status}>Allow and start</button>
      ) : (
        <>
          <button className="slide-next-btn" onClick={nextSlide} />
          <button className="slide-pause-btn" onClick={() => setIsProgressPaused(!isProgressPaused)}>
            <div className="pause-indicator">{isProgressPaused ? 'Tap to resume' : 'Tap to pause'}</div>
          </button>
        </>
      )}
    </div>
  );
};

export default Slider;