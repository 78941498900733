import { Link } from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation } from '../components/Animations'
import logo from '../assets/img/logo.svg'

const Footer = ({ brandName, brandEmail }) => {

  function goToPacksFunction(e){
    let plan = document.getElementById("pricing");
    e.preventDefault();  // Stop Page Reloading
    plan && plan.scrollIntoView({ behavior: "smooth", block: "start"});
  }

  function goUpFunction(e){
    let target = document.getElementById("up");
    e.preventDefault();  // Stop Page Reloading
    target && target.scrollIntoView({ behavior: "smooth", block: "start"});
  }

  function goToFAQFunction(e){
    let faq = document.getElementById("faq");
    e.preventDefault();  // Stop Page Reloading
    faq && faq.scrollIntoView({ behavior: "smooth", block: "start"});
  }

  return (
    <footer className="main-footer">
      <div className="te">
        <div className="grid-elementX">
          <img src={logo} className="logo small left" />
          <h4><strong>{brandName}</strong><br/> operates from European Union.</h4>
          <h4>A <a href="https://vonfediuc.com" target="_blank">VON FEDIUC</a> Venture.<br/><Link to="/legal" className="legal">LEGAL</Link>  ©{new Date().getFullYear()}<br/></h4>
        </div>
        <div className="grid-elementX">
          <h4 className="footer-link"><button className="clear" onClick={goUpFunction}><span className="gradient-text">UP</span></button></h4>
          <h4 className="footer-link"><button className="clear" onClick={goToFAQFunction}>FAQs</button></h4>
          <h4 className="footer-link"><button className="clear" onClick={goToPacksFunction}>PRICING</button></h4>
          <h4 className="footer-link"><Link to="/examples">DEMO APP</Link></h4>
          {/*<h4 className="footer-link"><Link to="https://billing.stripe.com/p/login/test_3cs6qf2uefyh5XOeUU">CLIENT LOGIN</Link></h4>*/}
          <h4 className="footer-link"><a href={'mailto:' + brandEmail}>CONTACT</a></h4>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
