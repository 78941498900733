import React, { useState, useEffect } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
// import { contentAnimation, listAnimation } from './components/Animations'
import ReactGA from "react-ga4";
import Footer from '../components/Footer'

function Examples() {

  //ANALYTICS
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/examples" });
  }, [])

  const eventTrack = (category, action, label) => {
  // console.log("GA event:", category, ":", action, ":", label);
  ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="navigation">
          <Link to="/"><button className="cancel-btn">Back</button></Link>
      </div>

      <div className="page">

        <header className="page-header">
          <div className="content wide">
            <br/><br/><br/>
            <h1 className="landing-page-title">Examples</h1>
            <p>Here is a sample of work depicting the high-level of quality that we are commited to deliver.</p>
            <br/><br/><br/>
          </div>
        </header>

        <section>
          <div className="content ">
            
          </div>
        </section>

      </div>
      <Footer/>
    </>
  );
}

export default Examples;
