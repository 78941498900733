import React, { useState } from 'react'

import { Link } from 'react-router-dom'

//AR
// import 'aframe';

// import 'mind-ar/dist/mindar-image.prod.js';
// import 'mind-ar/dist/mindar-face.prod.js';

// import 'mind-ar/dist/mindar-image-aframe.prod.js';
// import 'mind-ar/dist/mindar-face-aframe.prod.js';

//Components
import Controlls from './Controlls'
import Marker from './Marker'
import Face from './Face'

//assets
import videoSRC from '../../assets/video/enchanted-magic.mp4'
import logo from '../../assets/img/logo.svg'

const Camera = ({ openSparks }) => {

  const [marker, setMarker] = useState(true)
  const [face, setFace] = useState(false)

  const [UI, setUI] = useState(true)
  const [watermark, setWatermark] = useState(false)
  function openMarkerFunction(){
    setMarker(true)
    setFace(false)
  }

  function openFaceFilterFunction(){
    setFace(true)
    setMarker(false)
  }

  function hideUI(){
    setUI(false)
    setWatermark(true)
  }
  function showUI(){
    setUI(true)
    setWatermark(false)
  }

  function startRecFunction(){
    hideUI()
  }
  function stopRecFunction(){
    showUI()
  }


  return (
    <>
      <img src={logo} className="logo"/>

      { UI? <Controlls 
        showSparks={openSparks} 
        showFace={openFaceFilterFunction} 
        showMarker={openMarkerFunction}
        startRec={startRecFunction}
      /> :null}

      { watermark ? 
        <>
          <button onClick={stopRecFunction} className="touch">
            <h4 className="section-title  gradient-text slow center watermark">ENCHANT.VISION</h4>
          </button>
        </>
      :null }
      
      {marker ? 
        <>
          <div className="marker"> 
            <Marker />
          </div>
        </> 
      :null }

      {face ? 
        <>
          <div className="face">
            <Face />
          </div>
        </> 
      :null }
      </>
  );
}

export default Camera;
