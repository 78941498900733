import React, { useState, useEffect, useRef } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation } from '../Animations'

const Step1 = ({ brandEmail }) => {

  return (
    <>
      <motion.div 
        key="step1"
        className="step step1 contentAnimation"
        variants={ contentAnimation } 
        animate='show' 
        exit='hide'
      >
            <div className="grid">
              <div className="grid-element">
                <h2>Your Needs</h2> 
                <p>Help us understand your project.</p>
                <br/>
                <div className="field">
                  <textarea name="" id="" type="text" placeholder="What do you need? How do you envision the experience?" required  />
                </div>

                <div className="field">
                  <select>
                    <option>What is your goal?</option>
                    <option>Create an art instalation</option>
                    <option>Entertainment</option>
                    <option>Create educational materials</option>
                    <option>Create a marketing campaign</option>
                    <option>Showcase my products</option>
                    <option>Other</option>
                  </select>
                </div>
                
                <h3>When do you need your AR experience?</h3>
                
                <div className="field grid">
                  <div className="grid-element">
                    <h4 className="label">Starting date</h4>
                    <input type="date" id="start-date" name="Start Date" placeholder="DD/MM/YYYY"/>
                    <h4>First 3 days <strong>FREE</strong>, after 29,99 €/month.</h4>
                  </div>
                  <div className="grid-element">
                    <h4 className="label">End date</h4>
                    <input type="date" id="end-date" name="End Date" placeholder="DD/MM/YYYY"/>
                    <h4>*Leave blank if undefined.</h4>
                  </div>
                </div>
              </div>

              <div className="grid-element"/> 

            </div>

        
      </motion.div>
    </>
  );
}

export default Step1;
