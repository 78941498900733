import React, { useState, useEffect } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation, listAnimation } from '../components/Animations'
import ReactGA from "react-ga4";

import logo from '../assets/img/logo.svg';
import videoSRC from '../assets/video/enchanted-magic.mp4'

function Success() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="page thanks">
        <header className="page-header gradient">
          <div className="content center">
           
            <img src={logo} className="logo left" alt="logo"/>
            <h2>Success!</h2>
            <p>We've successfuly received your payment. Please give us a minute to process everything. We'll send you an email with the next steps, shortly.</p>
            <br/>
            <Link to="/">Go Home</Link>
            <br/><br/><br/><br/><br/><br/>

            <h4>If you haven't heard from us in the next 24h, something may have went wrong, so please <a href="mailto:hi@enchant.vision">contact us</a>.</h4>
          </div>
          {/*{<video className="landing-video dark" src={videoSRC} autoPlay="autoplay" playsInline="playsinline" loop="true" muted="true"/>}*/}
        </header>
      </div>
    </>
  );
}

export default Success;
