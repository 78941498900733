import { Link } from 'react-router-dom'

import sparkles from '../../assets/img/sparkles-ic.svg'

const Sparks = ({ closeSparks }) => {

  return (
   <>
     <section>
       <div className="content">
         <button className="close-btn" onClick={closeSparks} />
         <img src={sparkles} className="logo"/>
         <h2>Sparkles</h2>
         <h3>Place in space a sparkle</h3>
         <p>Sparkles are AR objects or experiences that you can place in your space.</p>
       </div>
     </section>
   </>
  );
}

export default Sparks;
