import React, { useState, useEffect } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation, formAnimation, indicatorAnimation } from '../components/Animations'

import Analytics from '../components/Analytics'
import { SEO } from '../components/SEO';

//STEPS
import Step1 from '../components/Form/Step1';
import Step2 from '../components/Form/Step2';
import Step3 from '../components/Form/Step3';
import Step4 from '../components/Form/Step4';

import logo from '../assets/img/logo.svg';
// import logoGrey from '../assets/img/logo-grey.svg';
// import videoSRC from '../assets/video/create.mp4'


function Form() {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [start, setStart] = useState(true)
  const [form, setForm] = useState(false)
  const [step1, setStep1] = useState(true)
  const [step2, setStep2] = useState(false)
  const [step3, setStep3] = useState(false)
  const [step4, setStep4] = useState(false)
  const [step5, setStep5] = useState(false)


  function showStartFunction(){
    setStart(true)
    setStep1(false)
    setStep2(false) 
    setStep3(false)
    setStep4(false)
    setStep5(false)
  }

  function showFormFunction(){
    setStart(false)
    setForm(true)
    setStep1(true)
    setStep2(false) 
    setStep3(false)
    setStep4(false)
    setStep5(false)
  }

  function showStep1Function(){
    setStart(false)
    setForm(true)
    setStart(false)
    setStep1(true)
    setStep2(false) 
    setStep3(false)
    setStep4(false)
    setStep5(false)
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
  }

  function showStep2Function(){
    setStart(false)
    setForm(true)
    setStart(false)
    setStep1(false)
    setStep2(true) 
    setStep3(false)
    setStep4(false)
    setStep5(false)
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    }); 
  }
  function showStep3Function(){
    setStart(false)
    setForm(true)
    setStart(false)
    setStep1(false)
    setStep2(false) 
    setStep3(true)
    setStep4(false)
    setStep5(false)
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
  }
  function showStep4Function(){
    setStart(false)
    setForm(true)
    setStart(false)
    setStep1(false)
    setStep2(false) 
    setStep3(false)
    setStep4(true)
    setStep5(false)
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
  }
  function showStep5Function(){
    setStart(false)
    setForm(true)
    setStart(false)
    setStep1(false)
    setStep2(false) 
    setStep3(false)
    setStep4(false)
    setStep5(true)
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
  }

  useEffect(() => {
    // window.scrollTo(0, 0);
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
  }, []);


  return (
    <>
      <Analytics pageName="Form"/>
      <SEO
        title="ENCHANT VISION - Configure"
        description="ENCHANT YOUR AUDIENCE WITH AN AR EXPEREIENCE"
        type="webapp"
        name="Enchant Vision"
      />

      <motion.div 
        key="page"
        className="page form contentAnimation"
        variants={ contentAnimation } 
        animate='show' 
        exit='hide'
      >
      
      {start ?
        <header className="page-header">
          <Link to="/"><button className="cancel-btn">Cancel</button></Link>
          <div className="content">
            <img src={logo} className="logo left" alt="logo"/>
            <h1 className="page-title">Let's start!</h1>
            <p>Please take your time and carefully fill in all the details. <br/>Based on this, we will create the concept of your AR experience.</p>
            <br/>
            <br/>
            {<button className="button" disabled onClick={showFormFunction}>Not available now</button>}
            {/*{<button className="button" onClick={showFormFunction}>Start</button>}*/}
            
            <h4>By continuing you are agreeing to our <br/><Link to="/legal">TOS & Privacy Policy</Link>.</h4>
          </div>
          {/*<video className="landing-video dark" src={videoSRC} autoPlay="autoplay" playsInline="playsinline" loop="true" muted="true"/>*/}
        </header>
      :null }

      { form ?
        <>
          <div className="navigation">
            <Link to="/"><button className="cancel-btn">Cancel</button></Link>
            
            <motion.div 
              key="step-indicator"
              className="step-indicator contentAnimation"
              variants={ indicatorAnimation } 
              animate='show' 
              exit='hide' 
            >
              <button className={`indicator ${!step1 ? "" : "active"}`} onClick={showStep1Function}>1</button>
              <button className={`indicator ${!step2 ? "" : "active"}`} onClick={showStep2Function}>2</button>
              <button className={`indicator ${!step3 ? "" : "active"}`} onClick={showStep3Function}>3</button>
              <button className={`indicator ${!step4 ? "" : "active"}`} onClick={showStep4Function}>4</button>
              {/*<button className={`indicator ${!step5 ? "" : "active"}`} onClick={showStep5Function}>5</button>*/}
            </motion.div>
          </div>
          <div className="nav-border"/>

          <motion.section 
            key="form"
            className="form contentAnimation"
            // variants={ formAnimation } 
            animate='show' 
            exit='hide'       
          > 
            <div className="content wide">
              <form action="https://public.herotofu.com/v1/20d297c0-4625-11ed-a484-c1a2ba854bab" method="post" accept-charset="UTF-8">
                { step1 ?
                  <>
                    <Step1 />
                    <div className="navigation bottom">
                      <button className="button next" onClick={showStep2Function}>Next: Technology</button>
                    </div>
                  </>
                :null }

                { step2 ?
                  <>
                    <Step2 />
                    <div className="navigation bottom">
                      <button className="button next" onClick={showStep3Function}>Next: Branding</button>
                    </div>
                  </>
                :null }

                { step3 ?
                  <>
                    <Step3 />
                    <div className="navigation bottom">
                      <button className="button next" onClick={showStep4Function}>Next: Final</button>
                    </div>
                  </>
                :null }

                {step4 ?
                  <>
                    <Step4 />
                  </>
                :null }

              </form>
            </div>
          </motion.section>
        </>
      :null }
      </motion.div>
    </>
  );
}

export default Form;
