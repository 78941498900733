import React, { useState, useEffect, useRef } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation } from '../Animations'

const Step4 = ({ validation }) => {

  //Checkbox
  const [isCorrect, setIsCorrect] = useState(false);

  const handleCheckboxChange = event => {
    if (event.target.checked) {
      console.log('✅ Checkbox is checked');
    } else {
      console.log('⛔️ Checkbox is NOT checked');
    }
    setIsCorrect(current => !current);
  };

  const [checkout, setCheckout] = useState(false)

  function showCheckoutFunction(){
    setCheckout(true);
  }

  return (
    <>
      <motion.div 
        key="step4"
        className="step step4 contentAnimation"
        variants={ contentAnimation } 
        animate='show' 
        exit='hide'
      >
        <div className="grid">    
          <div className="grid-element">
            <h2>Final</h2>
            <br/>
            <div className="field">
              <h4 className="label">Your Full Name</h4>
              <input className="input-field" name="Nume" id="name" type="text" placeholder="Your Name" required />
            </div>
            <div className="field">
              <h4 className="label">Your Email</h4>
              <input className="input-field" name="Email" id="email" type="email" placeholder="Your Email" required />
            </div>
            <div className="field">
              <h4 className="label">Is there anything else you would like to share or ask us about?</h4>
              <textarea className="input-field" name="Mesaj" id="message" type="text" placeholder="Optional message" required  />
            </div>
            <hr/>
            <h4><strong>Disclaimer:</strong> It is your responsability to provide us with the necessary information we asked for. Based on the information provided here, we will start the design proccess.</h4>
            <br/> 
            <h4 className="label">
              <input type="checkbox" id="correctInfo" name="correctInfo" value={isCorrect} onChange={handleCheckboxChange}/>
              <label for="correctInfo">I have double-checked all the information provided and it is complete and correct.</label>
            </h4>
            <div className="navigation bottom">

              <input disabled={!isCorrect} className="button primary submit-btn" type="submit" value="Checkout (3469 €)" onClick={showCheckoutFunction}/>
            </div>
            <div className="fuck-spam" aria-hidden="true">
              <input type="text" name="_gotcha" tabindex="-1" autocomplete="off" />
            </div>
          </div>
          <div className="grid-element"/>
        </div>
      </motion.div>
    </>
  );
}

export default Step4;
