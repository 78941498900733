import React, { useRef } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation } from '../components/Animations'
import logo from '../assets/img/logo.svg'
import videoSRC from '../assets/video/enchanted-magic.mp4'

function Header() {

  const scrollRef = useRef(null)

  return (
    <header className="page-header gradient">

      <motion.div 
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ root: scrollRef }}
        transition={{ delay: 0.3, duration: 1 }} // Add a delay of 0.5 seconds
      >
        <div className="content heading">
          {/*<h1 className="landing-page-title">AR Experiences designed to enchant your audience</h1>*/}
          <br/>
          <h1 className="landing-page-title">We design AR experiences to enchant your audience</h1>
        </div>

        <div className="content heading">
          <p>All that you need to offer unique, immersive Augmented Reality experiences to your audience.</p>
          <br/>
          <Link to="/start"><button className="button inline primary">Let's start!</button></Link>
          <button className="button inline secondary">DEMO</button>   
          <br/>
          <br/>
          <h4>It works on air! No need for app downloading!</h4>
        </div>
      </motion.div>
      <video className="landing-video dark" src={videoSRC} autoPlay="autoplay" playsInline="playsinline" loop="true" muted="true"/>
        
    </header>
  );
}

export default Header;
