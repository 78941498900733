import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

const Analytics = ({ pageName }) => {
  // ANALYTICS
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: 'pageview', page: pageName });
  }, []);

  const eventTrack = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  console.log(pageName);

  return <div></div>; // Return an empty div
};

export default Analytics;
