import React, { useState, useEffect } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
// import { contentAnimation, listAnimation } from './components/Animations'

import Analytics from '../components/Analytics'
import { SEO } from '../components/SEO'

import Camera from '../components/ViewerApp/Camera'
import Onboarding from '../components/ViewerApp/Onboarding'
import Sparks from '../components/ViewerApp/Sparks'

function ViewerApp() {

  const [onboarding, setOnboarding] = useState(true)
  const [camera, setCamera] = useState(false)
  const [sparks, setSparks] = useState(false)

  function openSparksFunction(){
    setSparks(true)
    setCamera(false)
  }
  function closeSparksFunction(){
    setSparks(false)
    setCamera(true)
  }

  function startCameraFunction(){
    setCamera(true)
    setOnboarding(false)
  }

  return (
    <>
      <Analytics pageName="AR Viwer"/>
      <SEO
        title="ENCHANT VISION - Sight"
        description="Your AR viwer"
        type="webapp"
        name="Enchant Vision"
      />

      {onboarding ? <Onboarding status={startCameraFunction}/> :null}
      {camera ? <Camera openSparks={openSparksFunction}/> :null}
      {sparks ? <Sparks closeSparks={closeSparksFunction}/> :null}
    </>
  );
}

export default ViewerApp;
