import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// import { AnimatePresence, motion } from 'framer-motion'
// import { contentAnimation, listAnimation } from './components/Animations'

import { SEO } from '../components/SEO';
import Analytics from '../components/Analytics';

//Components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function PageNotFound() {
  return (
    <>
      <Analytics pageName="Landing"/>
      <SEO
        title="ENCHANT VISION"
        description="ENCHANT YOUR AUDIENCE WITH AN AR EXPEREIENCE"
        type="webapp"
        name="Enchant Vision"
      />
      
      {/*<Navbar/>*/}
      <div className="page 404">
        <section className="gradient">

          <div className="content center">
            <h1 className="page-title">404.</h1>
            <p>Ups! It seems like we've got lost. Well, don't worry, here is the path back.</p>         
            <br/>
            <p><Link to="/"><button className="button big-btn">Return home</button></Link></p> 
          </div>
        </section>
      </div>
      <Footer/>
    </>
  );
}

export default PageNotFound;
