import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { AnimatePresence, motion, useViewportScroll, useTransform } from 'framer-motion'
import { navbarAnimation } from '../components/Animations'


function Navbar() {

  function goUpFunction(e){
    let target = document.getElementById("up");
    e.preventDefault();  // Stop Page Reloading
    target && target.scrollIntoView({ behavior: "smooth", block: "start"});
  }

  function goToPricingFunction(e){
    let plan = document.getElementById("pricing");
    e.preventDefault();  // Stop Page Reloading
    plan && plan.scrollIntoView({ behavior: "smooth", block: "start"});

    closeMenuFunction()
  }

  function goToFAQFunction(e){
    let faq = document.getElementById("faq");
    e.preventDefault();  // Stop Page Reloading
    faq && faq.scrollIntoView({ behavior: "smooth", block: "start"});

    closeMenuFunction()
  }

  const [menuOverlay, setMenuOverlay] = useState(false)

  function openMenuFunction(){
    setMenuOverlay(true)
  }

  function closeMenuFunction(){
    setMenuOverlay(false)
  }

  return (
    <>
      <motion.nav 
        key="navbar"
        className="main-navbar navbarAnimation"
        variants={ navbarAnimation } 
        animate='show' 
        exit='hide'
      >
        <a className="navbar-logo center" href="#up"><button className="clear" onClick={goUpFunction}></button></a>
          
        <ul className="desktop-menu">
          <li>
            </li>
          <li>
            <a href=""><button className="clear" onClick={goToFAQFunction}>FAQs</button></a>
          </li>
          <li>
            <a href=""><button className="clear" onClick={goToPricingFunction}>Pricing</button></a>
          </li>
          <li>
            <a href="mailto:hi@enchant.vision">Contact</a>
          </li>
          
        </ul>

        <Link to="/start" className="navbar-cta"><span className="gradient-text slow">Let's start</span></Link>
          
        <button className="menu-open-icon" onClick={openMenuFunction} />

      </motion.nav>

      { menuOverlay ? 

      <div className="menu-overlay">
        <button className="menu-close-icon" onClick={closeMenuFunction} />

        <ul className="mobile-menu">
          <li>
            <a href=""><button className="clear" onClick={goToFAQFunction}>FAQs</button></a>
          </li>
          <li>
            <a href=""><button className="clear" onClick={goToPricingFunction}>Pricing</button></a>
          </li>
          <li>
            <a href="mailto:hi@enchant.vision">Contact</a>
          </li>
        </ul>

      </div>

      :null }
    </>
  );
}

export default Navbar;
