import React, { useState, useEffect } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation, formAnimation, indicatorAnimation } from '../components/Animations'

import Analytics from '../components/Analytics'
import { SEO } from '../components/SEO';

import logo from '../assets/img/logo.svg';
// import logoGrey from '../assets/img/logo-grey.svg';
// import videoSRC from '../assets/video/create.mp4'


function Checkout() {

  useEffect(() => {
    // window.scrollTo(0, 0);
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
  }, []);


  return (
    <>
      <Analytics pageName="Analytics"/>
      <SEO
        title="ENCHANT VISION - Checkout"
        description="ENCHANT YOUR AUDIENCE WITH AN AR EXPEREIENCE"
        type="webapp"
        name="Enchant Vision"
      />

      <motion.div 
        key="page"
        className="page form contentAnimation"
        variants={ contentAnimation } 
        animate='show' 
        exit='hide'
      >
        <div className="content center">
            <h2 className="heading">All good!</h2>
            <p>Thank you for providing all the requested details.</p>
            <p>If we don't receive your payment in the next hour your details will be ignored.</p>
            <br/>
            <hr/>
            <br/>
            <h4>YOUR SUMMARY:</h4>
            
            <h3>Hacker Way <span className="red">Pack</span></h3>
            <ul>
              <li>+ CI (mini)</li>
              <li>+ Digital Presence</li>
              <li>+ Pitch Deck</li>
            </ul>
        
            <br/>
            <p>We'll start, as soon as we receive your payment*.</p>

            
              <Link to="https://buy.stripe.com/test_4gwg0N6GR5HHh1e000"><button className="button pay">Pay 2.999 €</button></Link>

            <br/>
            <h4>*Working days: Monday - Friday.</h4>
          </div>
      </motion.div>
    </>
  );
}

export default Checkout;
