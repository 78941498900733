import React from 'react';

const Slide = ({ image, title, description }) => {
  return (
    <section className="slide">
      <div className="content">
        <img className="slide-icon" src={image} alt={title} />
        <h2 className="center">{title}</h2>
        <p className="center">{description}</p>
      </div>
    </section>
  );
};

export default Slide;