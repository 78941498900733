import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation } from '../components/Animations'

import Analytics from '../components/Analytics';

import TOS from '../components/TOS'
import Footer from '../components/Footer'

function Legal() {
  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Analytics pageName="Legal"/>

      <div className="navigation">
        <button className="cancel-btn" onClick={() => navigate(-1)}>Back</button>
      </div>
      <motion.div
        key="landing"
        className="page legal contentAnimation"
        variants={ contentAnimation } 
        animate='show' 
        exit='hide'
      >
        <section>
          <div className="content">
            <br/><br/>
            <h1 className="page-title">Legal</h1>
            <h3>Terms & Conditions</h3>
            <h4>Last updated: 1/8/2023</h4>

            <TOS 
              brandName="ENCHANT VISION" 
              brandEmail="hi@enchant.vision" 
              brandUrl="https://enchant.vision" 
              companyName="RESURGAM DESIGN STUDIO SRL"
            />

          </div>
        </section>
      </motion.div>
    </>
  );
}

export default Legal;
