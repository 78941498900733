import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import Slider from './Onboarding/Slider';

import logo from '../../assets/img/logo.svg'

import face from '../../assets/img/face-ic.svg'
import marker from '../../assets/img/marker-ic.svg'
import sparkles from '../../assets/img/sparkles-ic.svg'
import camera from '../../assets/img/camera-ic.svg'

const Onboarding = ({ status }) => {

  const slides = [
    {
      image: logo,
      title: 'Hello!',
      description: 'Welcome to Sight the AR viewer app from Enchant Vision! Let\'s get started with three exciting features.'
    },
    {
      image: face,
      title: 'Face filter',
      description: 'Take a memorable selfie.',
    },
    {
      image: marker,
      title: 'Marker',
      description: 'By scanning a marker, you can bring to life a picture or you can trigger an enchanting experience.',
    },
    {
      image: sparkles,
      title: 'Sparkles',
      description: 'Sparkles are objects or expereinces that you can enjoy by placing them on a surface from your surroundings.',
    },
    {
      image: camera,
      title: 'Allow camera.',
      description: 'To enjoy these enchanting experiences, we\'ll need access to your device\'s camera. We only use your camera to show you the experiences. 100% privacy.',
    },
  ];

  return (
    <>
      <Slider slides={slides} status={status} />
    </>
  );
}

export default Onboarding;
