import React, { useState, useEffect, useRef } from 'react'
import {
  Link,
  Route,
  Routes,
  useRouteMatch,
  useParams,
  useLocation
} from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import { contentAnimation } from '../components/Animations'

const Pricing = ({ brandEmail }) => {

  const scrollRef = useRef(null)

  function goToFAQFunction(e){
    let plan = document.getElementById("faq");
    e.preventDefault();  // Stop Page Reloading
    plan && plan.scrollIntoView({ behavior: "smooth", block: "start"});
  }

  return (
    <>

    <div className="content wide">
      <p className="center highlighted gradient-text slow">Now stop scrolling.</p>
      <motion.div 
        // ref={scrollRef}
        // style={{overflow: scroll'}}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ root: scrollRef }}
        transition={{ delay: 0.3, duration: 1 }} // Add a delay of 0.5 seconds
      >
        <h2 className="heading center">Pricing and configuration</h2>
      </motion.div>
    </div>  
        
        <div className="">
          <div className="grid">
            
            <div className="grid-element boxed gradient">
              <h4 className="section-title">One time payment</h4>
              <h2 className="heading"><span className="gradient-text">AR-X Design</span></h2>
              <p>Based on your brand needs, we create an artistic concept and design your experience specially to engage your audience.</p>
              <br/>
              <h2 className="center"><sup>starting</sup> 3,469 €</h2>
              <Link to="/start"><button className="button center">Commission Request</button></Link>
              <h4 className="center">*Configure your design pakage.</h4>
            </div>

            <div className="grid-element boxed pro">       
              <h4 className="section-title">Subscription plan</h4>
              <h2 className="heading">Extension</h2>
              <p>Your experience will be available for free for up to 3 days choosen by you. After that, you can extend on a monthly basis.</p>
              <br/>
              <h2 className="center">29.99 € <sup>/ Month</sup></h2>
              <Link to="/start"><button disabled className="button secondary center disabled">For customers only</button></Link>
              <h4 className="center">*Available only for existing customers.</h4> 
            </div>

          </div>

          <div className="grid">
            <div className="grid-element">
              <h3>You get:</h3>
              <ul>
                <li>Artistic concept</li>
                <li>2D/3D animation for Web AR</li>
                <li>Branded Viewer App<sup>* with your logo</sup></li> 
                <li>Branded QR code sticker</li>
                <li>FREE Experience Hosting (3 days)</li>
                <li>Analytics</li>
              </ul>
            </div>

            <div className="grid-element">
              <h2 className="center">Have questions?</h2>
              <button className="button small" onClick={goToFAQFunction}>Read FAQs</button> 
              <p className="center">or <a href={'mailto:' + brandEmail}>write us</a>.</p>
            </div>
          </div>
        </div>
    </>
  );
}

export default Pricing;
