import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Controlls = ({ showSparks, showFace, showMarker, startRec }) => {

  const [cameraFront, setCameraFront] = useState(false);
  const [cameraBack, setCameraBack] = useState(false);

  return (
    <>
     <div className="app-controlls">

        <div className="camera-switch">
          {!cameraFront && <button onClick={() => {setCameraFront(true); setCameraBack(false); showFace()}} className="switch face"></button>}
          {cameraFront && <button onClick={() => {setCameraFront(false); setCameraBack(true); showMarker()}} className="switch marker"></button>}
        </div>

        <button className="record-btn" onClick={startRec}>
          <span className="red-dot"/>
        </button>

        <button className="switch sparkles" onClick={showSparks}></button>
     </div>
    </>
  );
}

export default Controlls;
